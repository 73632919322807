import React, { FC } from "react";

interface AvatarIconProps {
  className?: string;
}

const AvatarIcon: FC<AvatarIconProps> = ({ className }) => (
  <svg
    className={className}
    width="13"
    height="15"
    viewBox="0 0 13 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.49981 0.166687C8.49831 0.166687 10.1184 1.80293 10.1184 3.82134C10.1184 5.11219 9.45578 6.24673 8.4553 6.89692C10.0881 6.96724 11.1909 7.1392 11.5151 7.46024C12.354 8.29085 12.9184 12.7577 12.1042 13.5638C11.2924 14.3676 1.64496 14.3676 0.833146 13.5638C0.0935824 12.8316 0.735629 8.75031 1.30719 7.61845C1.54877 7.14007 2.62703 6.94567 4.52321 6.88271C3.53458 6.23083 2.8812 5.10315 2.8812 3.82134C2.8812 1.80293 4.5013 0.166687 6.49981 0.166687ZM6.36044 8.0756L6.08231 8.07636C5.16881 8.08141 4.30224 8.11129 3.60644 8.16268L3.381 8.1806L3.23201 8.19401C2.94179 8.22149 2.69859 8.2529 2.51142 8.28699L2.42255 8.30425L2.31672 8.32838L2.29367 8.3932C1.97953 9.32982 1.56551 11.9621 1.64033 12.5749L1.64984 12.6309L1.65693 12.6524L1.73985 12.6745L1.87077 12.7022L1.94722 12.7161C2.1575 12.7524 2.42863 12.7857 2.75148 12.815L3.09123 12.8429L3.32692 12.8594C3.96059 12.9009 4.70929 12.9286 5.50443 12.941L5.84784 12.9454L6.46868 12.9485C7.61575 12.9485 8.72531 12.9173 9.61043 12.8594L9.84612 12.8429L10.0202 12.8293C10.2467 12.8107 10.451 12.7902 10.6302 12.7682L10.7599 12.7514L11.0666 12.7022L11.1975 12.6745L11.2505 12.661L11.2737 12.5487C11.2928 12.4363 11.306 12.2943 11.312 12.1283C11.327 11.7076 11.2947 11.1635 11.2222 10.5891C11.1025 9.6418 10.8788 8.74369 10.7327 8.43147L10.701 8.37041L10.6131 8.34576L10.516 8.32337L10.3162 8.28627C10.1954 8.26642 10.0573 8.24758 9.90339 8.22993L9.74431 8.2127L9.39608 8.18078C8.56439 8.11259 7.47823 8.0756 6.36044 8.0756ZM6.49981 1.3849C5.16747 1.3849 4.0874 2.47573 4.0874 3.82134C4.0874 5.16695 5.16747 6.25778 6.49981 6.25778C7.83214 6.25778 8.91221 5.16695 8.91221 3.82134C8.91221 2.47573 7.83214 1.3849 6.49981 1.3849Z"
      fill="currentColor"
    />
  </svg>
);

export default AvatarIcon;
