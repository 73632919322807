export enum CommonLayoutActionType {
  GET_COMMONS = "@COMMON_LAYOUT/GET_COMMONS",
  GET_COMMONS_SUCCESS = "@COMMON_LAYOUT/GET_COMMONS_SUCCESS",
  GET_COMMONS_FAILURE = "@COMMON_LAYOUT/GET_COMMONS_FAILURE",

  GET_PROJECTS = "@COMMON_LAYOUT/GET_PROJECTS",
  GET_PROJECTS_SUCCESS = "@COMMON_LAYOUT/GET_PROJECTS_SUCCESS",
  GET_PROJECTS_FAILURE = "@COMMON_LAYOUT/GET_PROJECTS_FAILURE",

  ADD_OR_UPDATE_PROJECT = "@COMMON_LAYOUT/ADD_OR_UPDATE_PROJECT",

  UPDATE_COMMON_OR_PROJECT = "@COMMON_LAYOUT/UPDATE_COMMON_OR_PROJECT",

  SET_CURRENT_COMMON_ID = "@COMMON_LAYOUT/SET_CURRENT_COMMON_ID",

  RESET_CURRENT_COMMON_ID_AND_PROJECTS = "@COMMON_LAYOUT/RESET_CURRENT_COMMON_ID_AND_PROJECTS",

  SET_LAST_COMMON_FROM_FEED = "@COMMON_LAYOUT/SET_LAST_COMMON_FROM_FEED",

  CLEAR_DATA = "@COMMON_LAYOUT/CLEAR_DATA",

  CLEAR_DATA_EXCEPT_OF_CURRENT = "@COMMON_LAYOUT/CLEAR_DATA_EXCEPT_OF_CURRENT",

  CLEAR_PROJECTS = "@COMMON_LAYOUT/CLEAR_PROJECTS",

  REMOVE_MEMBERSHIP_FROM_PROJECTS_BY_ROOT_COMMON_ID = "@COMMON_LAYOUT/REMOVE_MEMBERSHIP_FROM_PROJECTS_BY_ROOT_COMMON_ID",

  MARK_DATA_AS_NOT_FETCHED = "@COMMON_LAYOUT/MARK_DATA_AS_NOT_FETCHED",

  REMOVE_MEMBERSHIP_FROM_ITEM_AND_CHILDREN = "@COMMON_LAYOUT/REMOVE_MEMBERSHIP_FROM_ITEM_AND_CHILDREN",

  DELETE_COMMON = "@COMMON_LAYOUT/DELETE_COMMON",
}
