import React, { ReactElement } from "react";

interface CalendarIconProps {
  className?: string;
}

export default function CalendarIcon({
  className,
}: CalendarIconProps): ReactElement {
  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.816 12.832c.03-.261.057-.602.082-1.005.057-.942.092-2.168.1-3.395a56.077 56.077 0 0 0-.064-3.353c-.02-.342-.043-.63-.07-.855-.3-.03-.7-.061-1.173-.089a86.806 86.806 0 0 0-3.696-.126 61.465 61.465 0 0 0-3.677.041 14.11 14.11 0 0 0-1.144.094c-.037.267-.07.629-.097 1.068-.056.93-.081 2.133-.076 3.342a58.1 58.1 0 0 0 .097 3.318c.025.383.053.705.084.951.285.034.667.064 1.127.09 1.027.056 2.37.086 3.72.087 1.351.001 2.691-.027 3.71-.083.438-.024.802-.053 1.077-.085zM3.243 3.791l-.006.02a.103.103 0 0 1 .006-.02zm9.553.02zM2.049 14.62a1.197 1.197 0 0 1-.412-.273 1.217 1.217 0 0 1-.241-.374 1.882 1.882 0 0 1-.083-.249 4.356 4.356 0 0 1-.092-.476A16.688 16.688 0 0 1 1.103 12 60.044 60.044 0 0 1 1 8.562a56.83 56.83 0 0 1 .08-3.471c.03-.491.07-.937.122-1.29.026-.175.058-.349.1-.504.03-.109.105-.373.292-.594.14-.166.301-.257.393-.302a1.82 1.82 0 0 1 .289-.112c.169-.051.359-.087.546-.115.379-.056.862-.095 1.395-.121a63.367 63.367 0 0 1 3.805-.044c1.361.02 2.725.067 3.786.13.53.03.994.066 1.348.105.176.02.34.04.476.065.068.012.142.028.216.048a1.134 1.134 0 0 1 .735.566c.058.106.096.21.12.288.05.156.083.332.109.497.052.337.09.773.117 1.256.056.973.076 2.23.068 3.48a68.738 68.738 0 0 1-.104 3.503c-.03.491-.066.93-.11 1.268-.02.167-.047.33-.08.472-.015.07-.037.152-.067.236a1.236 1.236 0 0 1-.202.363c-.164.2-.362.292-.435.324a1.801 1.801 0 0 1-.271.093c-.156.04-.334.07-.513.095-.363.049-.836.087-1.367.116a72.612 72.612 0 0 1-3.82.086 74.48 74.48 0 0 1-3.83-.09 19.57 19.57 0 0 1-1.376-.116 4.783 4.783 0 0 1-.51-.09 1.926 1.926 0 0 1-.264-.084z"
        fill="currentColor"
      />
      <path
        d="M2.357 4.064c-.513.11-.437 1.79 0 1.873.438.083 10.919.086 11.304 0 .386-.086.514-1.818 0-1.873-.514-.055-10.79-.11-11.304 0z"
        fill="currentColor"
      />
      <rect x="3" y="1" width="2" height="4" rx="1" fill="currentColor" />
      <rect x="11" y="1" width="2" height="4" rx="1" fill="currentColor" />
      <path
        d="M4.06 7.064c-.086.11-.073 1.79 0 1.873.073.083 1.82.086 1.884 0 .064-.086.085-1.818 0-1.873-.086-.055-1.799-.11-1.884 0z"
        fill="currentColor"
      />
    </svg>
  );
}
