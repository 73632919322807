import React, { FC } from "react";

interface GroupChatIconProps {
  className?: string;
}

const GroupChatIcon: FC<GroupChatIconProps> = ({ className }) => (
  <svg
    className={className}
    width="40"
    height="41"
    viewBox="0 0 40 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect y="0.5" width="40" height="40" rx="20" fill="#DDDDDD" />
    <g clipPath="url(#clip0_11827_31246)">
      <path
        d="M20.4725 19.3477C16.9225 19.3477 14.0352 22.7649 14.0352 24.9519C14.0352 27.1389 16.9186 28.4824 20.4725 28.4824C24.0265 28.4824 26.9138 27.1389 26.9138 24.9675C26.9138 22.7961 24.0501 19.3477 20.4725 19.3477Z"
        fill="#464853"
      />
      <path
        d="M20.4761 18.7248C22.4541 18.7248 24.0577 16.8836 24.0577 14.6124C24.0577 12.3412 22.4541 10.5 20.4761 10.5C18.4981 10.5 16.8945 12.3412 16.8945 14.6124C16.8945 16.8836 18.4981 18.7248 20.4761 18.7248Z"
        fill="#464853"
      />
      <path
        d="M28.2311 19.043C27.1982 19.0645 26.2013 19.4228 25.395 20.0623C27.1227 21.4175 28.2311 23.3389 28.2311 24.7878C28.2304 25.1417 28.1593 25.4921 28.022 25.8188H28.2311C30.866 25.8188 32.9999 24.8112 32.9999 23.1905C32.9999 21.5698 30.866 19.043 28.2311 19.043Z"
        fill="#464853"
      />
      <path
        d="M28.2352 18.5885C29.7013 18.5885 30.8898 17.2247 30.8898 15.5423C30.8898 13.8599 29.7013 12.4961 28.2352 12.4961C26.7691 12.4961 25.5806 13.8599 25.5806 15.5423C25.5806 17.2247 26.7691 18.5885 28.2352 18.5885Z"
        fill="#464853"
      />
      <path
        d="M15.6049 20.0623C14.7986 19.4228 13.8017 19.0645 12.7689 19.043C10.134 19.043 8 21.5737 8 23.1944C8 24.8152 10.134 25.8227 12.7689 25.8227H12.9779C12.8402 25.4975 12.7692 25.1483 12.7689 24.7956C12.7689 23.3389 13.8772 21.4175 15.6049 20.0623Z"
        fill="#464853"
      />
      <path
        d="M12.7689 18.5885C14.235 18.5885 15.4235 17.2247 15.4235 15.5423C15.4235 13.8599 14.235 12.4961 12.7689 12.4961C11.3028 12.4961 10.1143 13.8599 10.1143 15.5423C10.1143 17.2247 11.3028 18.5885 12.7689 18.5885Z"
        fill="#464853"
      />
    </g>
    <defs>
      <clipPath id="clip0_11827_31246">
        <rect
          width="25"
          height="18"
          fill="white"
          transform="translate(8 10.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default GroupChatIcon;
