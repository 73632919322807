import React, { ReactElement } from "react";

interface GalleryIconProps {
  className?: string;
}

export default function GalleryIcon({
  className,
}: GalleryIconProps): ReactElement {
  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.887 8.027c1.023 0 2.049 1.242 2.98 3.834-.095 1.944-.23 3.413-.363 3.626-.208.33-3.478.501-6.901.512h-.735c-2.242-.007-4.462-.083-5.846-.226.745-1.462 1.405-2.623 1.982-3.482.767-1.141 1.364-1.697 1.64-1.697.364 0 .584.193 1.007.87l.217.354c.609.973 1.032 1.344 1.88 1.344.842 0 1.322-.635 2.002-2.107l.453-.999c.674-1.443 1.144-2.03 1.684-2.03zM15.504.513c.347.276.483 2.656.495 5.442v.735c-.003.921-.018 1.87-.043 2.788C15.079 7.84 14.069 7 12.887 7c-1.177 0-1.819.848-2.735 2.87l-.433.949c-.442.924-.752 1.316-.971 1.316-.365 0-.586-.193-1.008-.87l-.217-.354c-.609-.973-1.032-1.344-1.88-1.344-1.183 0-2.656 1.965-4.694 6.039a.857.857 0 0 1-.304-.119c-.86-.684-.86-14.29 0-14.974.414-.33 3.683-.5 7.054-.512h.75c3.371.011 6.64.182 7.055.512zM4.5 3a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
