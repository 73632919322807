export enum MultipleSpacesLayoutActionType {
  RESET = "@MULTIPLE_SPACES_LAYOUT/RESET",

  CONFIGURE_BREADCRUMBS_DATA = "@MULTIPLE_SPACES_LAYOUT/CONFIGURE_BREADCRUMBS_DATA",

  FETCH_BREADCRUMBS_ITEMS_BY_COMMON_ID = "@MULTIPLE_SPACES_LAYOUT/FETCH_BREADCRUMBS_ITEMS_BY_COMMON_ID",
  FETCH_BREADCRUMBS_ITEMS_BY_COMMON_ID_SUCCESS = "@MULTIPLE_SPACES_LAYOUT/FETCH_BREADCRUMBS_ITEMS_BY_COMMON_ID_SUCCESS",
  FETCH_BREADCRUMBS_ITEMS_BY_COMMON_ID_FAILURE = "@MULTIPLE_SPACES_LAYOUT/FETCH_BREADCRUMBS_ITEMS_BY_COMMON_ID_FAILURE",
  FETCH_BREADCRUMBS_ITEMS_BY_COMMON_ID_CANCEL = "@MULTIPLE_SPACES_LAYOUT/FETCH_BREADCRUMBS_ITEMS_BY_COMMON_ID_CANCEL",

  SET_BREADCRUMBS_DATA = "@MULTIPLE_SPACES_LAYOUT/SET_BREADCRUMBS_DATA",

  CLEAR_BREADCRUMBS = "@MULTIPLE_SPACES_LAYOUT/CLEAR_BREADCRUMBS",

  ADD_OR_UPDATE_PROJECT_IN_BREADCRUMBS = "@MULTIPLE_SPACES_LAYOUT/ADD_OR_UPDATE_PROJECT_IN_BREADCRUMBS",

  UPDATE_PROJECT_IN_BREADCRUMBS = "@MULTIPLE_SPACES_LAYOUT/UPDATE_PROJECT_IN_BREADCRUMBS",

  SET_BACK_URL = "@MULTIPLE_SPACES_LAYOUT/SET_BACK_URL",

  SET_MIN_WIDTH = "@MULTIPLE_SPACES_LAYOUT/SET_MIN_WIDTH",

  DELETE_COMMON = "@MULTIPLE_SPACES_LAYOUT/DELETE_COMMON",
}
