import React, { ReactElement } from "react";

interface LinkIconProps {
  className?: string;
}

export default function LinkIcon({ className }: LinkIconProps): ReactElement {
  return (
    <svg
      className={className}
      width="16"
      height="17"
      viewBox="0 0 16 17"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.213 7.034c.198.498.116.983-.231 1.965-.178.502-.75.772-1.28.603-.528-.169-.812-.713-.635-1.215l.096-.28.068-.224.029-.113-.158-.095-.216-.117a15.117 15.117 0 0 0-1.651-.703 16.541 16.541 0 0 0-1.471-.466l-.252-.062-.217-.046-.186-.031-.078.127-.188.329-.216.404-.292.583a45.678 45.678 0 0 0-.763 1.658l-.195.449a44.12 44.12 0 0 0-.972 2.457l-.102.295c-.08.232-.146.44-.197.618l-.076.28.074.045.138.077.156.082a16.23 16.23 0 0 0 1.712.726c.64.234 1.262.425 1.73.531l.262.053.153-.29.129-.268.235-.514c.219-.487.812-.715 1.325-.507.513.208.752.772.533 1.26l-.274.599-.183.373c-.227.443-.391.68-.588.878l-.079.076C6.323 17.55.548 15.443.041 14.17c-.454-1.142 2.97-9.152 4.111-9.712 1.221-.6 6.576 1.354 7.061 2.577zm4.743-3.554c.454 1.141-2.713 8.952-3.854 9.512-1.221.6-6.575-1.355-7.06-2.577-.199-.498-.117-.983.23-1.965.178-.502.751-.773 1.28-.604.528.17.813.714.635 1.216l-.133.399-.06.218.158.095.217.116c.436.225 1.026.476 1.65.704.522.19 1.036.353 1.472.466l.251.061c.116.027.222.049.315.065l.082.012.063-.105.177-.318.201-.392.273-.567c.226-.483.468-1.035.709-1.613l.18-.438c.398-.982.751-1.94.987-2.682l.127-.42c.019-.063.036-.124.051-.183l.069-.278-.15-.086-.227-.122a16.23 16.23 0 0 0-1.712-.726 15.172 15.172 0 0 0-1.73-.53l-.263-.054-.152.291c-.059.12-.126.26-.201.423l-.163.358c-.218.488-.812.715-1.325.507-.513-.207-.752-.771-.533-1.26l.19-.416.178-.377c.25-.51.421-.78.617-.993l.139-.14c1.03-.979 6.805 1.129 7.312 2.403z"
        fill="#7786FF"
        fillRule="nonzero"
      />
    </svg>
  );
}
