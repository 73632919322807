export enum ROUTE_PATHS {
  HOME = "/",
  MOBILE_LOADER = "/mobile-loader",
  CONTACT_US = "/contact-us",
  MY_ACCOUNT = "/my-account",
  V04_PROFILE = "/profile-v04",
  PROFILE = "/profile",
  V04_BILLING = "/billing-v04",
  BILLING = "/billing",
  V04_SETTINGS = "/settings-v04",
  SETTINGS = "/settings",
  MY_ACCOUNT_ACTIVITIES = "/my-account/activities",
  MY_ACCOUNT_ACTIVITIES_PROPOSALS = "/my-account/activities/proposals/:proposalType",
  MY_ACCOUNT_ACTIVITIES_COMMONS = "/my-account/activities/commons",
  V02_COMMON = "/commons-v02/:id",
  V03_COMMON = "/commons-v03/:id",
  V04_COMMON = "/commons-v04/:id",
  V04_COMMON_ABOUT_TAB = "/commons-v04/:id/about",
  V04_COMMON_MEMBERS_TAB = "/commons-v04/:id/members",
  V04_COMMON_WALLET_TAB = "/commons-v04/:id/wallet",
  V04_PROJECT_CREATION = "/commons-v04/:id/new-project",
  V04_COMMON_EDITING = "/commons-v04/:id/edit",
  V04_COMMON_CREATION = "/commons-v04/new",
  COMMON_CREATION = "/commons/new",
  COMMON = "/commons/:id",
  COMMON_ABOUT_TAB = "/commons/:id/about",
  COMMON_MEMBERS_TAB = "/commons/:id/members",
  COMMON_WALLET_TAB = "/commons/:id/wallet",
  PROJECT_CREATION = "/commons/:id/new-project",
  COMMON_EDITING = "/commons/:id/edit",
  COMMON_SUPPORT = "/commons/:id/support",
  MY_COMMONS = "/my-commons",
  SUBMIT_INVOICES = "/invoices/submission/:proposalId",
  TRUSTEE = "/trustee",
  TRUSTEE_AUTH = "/trustee/auth",
  TRUSTEE_INVOICES = "/trustee/invoices",
  TRUSTEE_INVOICE = "/trustee/invoices/:proposalId",
  PROPOSAL_DETAIL = "/proposals/:id",
  PROPOSAL_COMMENT = "/proposal-comments/:id",
  DISCUSSION_DETAIL = "/discussions/:id",
  DISCUSSION_MESSAGE = "/discussion-messages/:id",
  DEAD_SEA = "/dead-sea",
  PARENTS_FOR_CLIMATE = "/parents-for-climate",
  SAVE_SAADIA = "/save-saadia",
  V04_INBOX = "/inbox-v04",
  INBOX = "/inbox",
  PRIVACY_POLICY = "/privacy-policy",
}
