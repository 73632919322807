import React, { FC } from "react";

interface PeopleGroupIconProps {
  className?: string;
}

const PeopleGroupIcon: FC<PeopleGroupIconProps> = ({ className }) => (
  <svg
    className={className}
    width="24"
    height="16"
    viewBox="0 0 24 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.38125 4.56309C6.38125 5.15212 6.14714 5.75055 5.79502 6.19993C5.43319 6.66171 5.01852 6.88218 4.70404 6.88218C4.37377 6.88218 3.95856 6.65934 3.60152 6.20103C3.25276 5.75336 3.02261 5.15478 3.02261 4.56309C3.02261 3.39511 3.79821 2.71313 4.70404 2.71313C5.60864 2.71313 6.38125 3.39383 6.38125 4.56309Z"
      stroke="currentColor"
    />
    <path
      d="M18.3332 6.88218H18.277C17.957 6.85859 17.5692 6.63607 17.2332 6.20363C16.8854 5.75611 16.656 5.15739 16.656 4.56519C16.656 3.39435 17.4238 2.71313 18.3332 2.71313C19.2432 2.71313 20.0104 3.39286 20.0104 4.56309C20.0104 5.15212 19.7763 5.75055 19.4242 6.19993C19.0624 6.66171 18.6477 6.88218 18.3332 6.88218Z"
      stroke="currentColor"
    />
    <path
      d="M14.3956 3.60802C14.3956 4.57705 14.01 5.55091 13.4365 6.28105C12.8529 7.02398 12.1478 7.42923 11.5569 7.42923C10.9368 7.42923 10.2341 7.02125 9.66201 6.28289C9.09806 5.55498 8.72461 4.58056 8.72461 3.60802C8.72461 1.67619 10.0091 0.5 11.5569 0.5C13.1061 0.5 14.3956 1.67555 14.3956 3.60802Z"
      stroke="currentColor"
    />
    <path
      d="M17.8916 13.7252L17.4486 13.4933C17.362 13.6588 17.2057 13.8182 16.7589 13.9716C16.2942 14.1311 15.5868 14.2595 14.4809 14.394C12.5363 14.6057 10.5743 14.6057 8.62968 14.394C7.5241 14.2596 6.81662 14.1307 6.35196 13.9709C5.90684 13.8178 5.74964 13.659 5.66282 13.4949C5.65376 13.4766 5.63204 13.4077 5.63629 13.2606C5.64035 13.1202 5.66669 12.9509 5.71026 12.7691C5.7983 12.4018 5.94005 12.0503 6.02434 11.8908L6.02958 11.8808L6.03437 11.8707C6.31924 11.2674 6.70712 10.7178 7.18081 10.2466L7.18086 10.2466L7.18641 10.2409C7.35847 10.0642 7.75878 9.80283 8.23903 9.62375C8.72742 9.44162 9.17521 9.39075 9.4701 9.48913L9.49642 9.49791L9.52356 9.50373C9.7238 9.54664 9.8253 9.60292 9.9822 9.6899C10.0436 9.72396 10.1136 9.76273 10.2013 9.80725C10.5197 9.96895 10.8981 10.0913 11.5564 10.0913C12.2142 10.0913 12.5921 9.96967 12.9104 9.80804C12.9988 9.76314 13.0691 9.72409 13.1309 9.68979C13.2868 9.6032 13.3881 9.54693 13.5884 9.50344L13.6148 9.49769L13.6405 9.48913C13.9354 9.39074 14.3843 9.44164 14.8733 9.6238C15.3545 9.80308 15.7536 10.0642 15.9226 10.2393L15.9226 10.2394L15.9298 10.2466C16.4035 10.7178 16.7914 11.2674 17.0762 11.8707L17.076 11.8708L17.0824 11.8834C17.1663 12.0488 17.3092 12.4038 17.3983 12.7706C17.4426 12.9528 17.4696 13.122 17.4741 13.2621C17.4788 13.4113 17.4563 13.4786 17.4481 13.4943L17.8916 13.7252ZM17.8916 13.7252C18.1534 13.2224 17.7311 12.0569 17.5284 11.6572L8.57246 14.8908C10.5551 15.1067 12.5555 15.1067 14.5381 14.8908C16.7533 14.6215 17.56 14.3585 17.8916 13.7252Z"
      stroke="currentColor"
    />
    <path
      d="M15.4785 8.46364C15.8903 8.18804 16.5808 7.92927 17.0581 8.08916C17.5438 8.19224 17.5565 8.46364 18.3251 8.46364C19.0938 8.46364 19.117 8.19224 19.5922 8.08916C20.1856 7.8893 21.1084 8.34162 21.4167 8.66139C21.7521 8.99555 22.0272 9.38485 22.2298 9.81217H22.2107C22.3417 10.073 22.6162 10.8325 22.4451 11.1607C22.2297 11.5731 21.7039 11.7456 20.2595 11.9202C19.6851 11.9835 19.1079 12.0179 18.53 12.0233"
      stroke="currentColor"
    />
    <path
      d="M7.56968 8.48892C7.16 8.20701 6.45045 7.93141 5.96264 8.09551C5.4875 8.19859 5.46427 8.46998 4.6956 8.46998C3.92692 8.46998 3.91425 8.19859 3.42855 8.09551C2.83304 7.89564 1.91232 8.34796 1.6019 8.66774C1.26713 9.00199 0.992804 9.3913 0.79099 9.81852C0.65795 10.0794 0.383423 10.8389 0.554474 11.1671C0.765649 11.5878 1.2957 11.7519 2.74013 11.9265C3.33841 11.9918 3.93961 12.0269 4.54144 12.0317"
      stroke="currentColor"
    />
  </svg>
);

export default PeopleGroupIcon;
