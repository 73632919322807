import React, { ReactElement } from "react";

interface ContributionIconProps {
  className?: string;
}

export default function ContributionIcon({
  className,
}: ContributionIconProps): ReactElement {
  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.315 9.746c.77 0 1.453.088 2.046.263.105.031.214.047.323.047h1.814c.462 0 .837.417.837.932 0 .514-.375.93-.837.93h-2.51c-.231 0-.418.21-.418.466 0 .258.187.466.418.466h3.14c.334 0 .653-.147.888-.41l1.514-1.684c.374-.416.947-.51 1.413-.234.36.213.495.71.304 1.109a.835.835 0 0 1-.076.128l-1.45 2.009c-.715.99-1.795 1.565-2.936 1.565h-5.15c-.584 0-1.159-.16-1.674-.465l-.507-.302a1.155 1.155 0 0 0-.59-.164h-.942c-.693 0-1.255-.625-1.255-1.397v-1.347c0-.727.53-1.316 1.183-1.316.076 0 .153.008.229.025l.216.047A3.41 3.41 0 0 0 4.41 10.2c.685-.302 1.32-.453 1.904-.453zm3.878-9.08c.676 0 1.295.3 1.74.844.424.517.657 1.207.657 1.944 0 2.45-2.824 5.593-4.393 5.593S3.804 5.905 3.804 3.454c0-.737.234-1.427.657-1.944C4.907.966 5.525.667 6.2.667c.756 0 1.41.387 1.894 1.12.037.055.07.11.102.165.032-.055.066-.11.102-.164.483-.734 1.138-1.121 1.894-1.121z"
        fill="currentColor"
      />
    </svg>
  );
}
