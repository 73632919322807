import React, { FC } from "react";

const AppleIcon: FC = () => {
  const color = "currentColor";

  return (
    <svg
      width="41"
      height="41"
      viewBox="0 0 41 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.515 11.262a5.04 5.04 0 0 1-2.011.372c-.133-.002-.183-.037-.19-.177-.109-1.318.244-2.524.88-3.66 1.086-1.94 2.738-3.137 4.885-3.664.183-.046.372-.07.572-.096.088-.012.178-.023.27-.037l.012.252c.006.122.011.238.014.353.046 2.38-1.492 5.49-4.432 6.657zm11.128 17.271.102-.296c-2.599-1.293-4.184-3.285-4.381-6.23-.196-2.944 1.148-5.118 3.509-6.764a3.764 3.764 0 0 0-.062-.091l-.01-.013c-.935-1.313-2.175-2.215-3.698-2.725a8.245 8.245 0 0 0-3.312-.415c-.756.06-1.482.272-2.195.532-.695.258-1.39.522-2.082.787-.626.24-1.249.24-1.874.004-.68-.254-1.357-.51-2.033-.77-1.285-.494-2.596-.624-3.944-.306-2.24.524-3.943 1.796-5.15 3.737-.962 1.545-1.38 3.26-1.487 5.06-.09 1.5.058 2.982.367 4.45.717 3.417 2.201 6.466 4.413 9.164.467.568.967 1.104 1.561 1.544.48.355.996.63 1.586.74.676.127 1.34.04 1.98-.174.385-.13.762-.284 1.138-.439.163-.067.327-.134.491-.199 1.503-.594 3.023-.667 4.551-.112.305.111.604.24.903.367.163.07.326.14.49.206.64.26 1.305.387 1.998.364.923-.032 1.72-.386 2.413-.973.35-.295.69-.613.981-.965 1.51-1.805 2.754-3.775 3.573-5.994.061-.163.117-.328.172-.489z"
        fill={color}
      />
    </svg>
  );
};

export default AppleIcon;
