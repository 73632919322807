export enum ProjectsActionType {
  GET_PROJECTS = "@PROJECTS/GET_PROJECTS",
  GET_PROJECTS_SUCCESS = "@PROJECTS/GET_PROJECTS_SUCCESS",
  GET_PROJECTS_FAILURE = "@PROJECTS/GET_PROJECTS_FAILURE",

  ADD_OR_UPDATE_PROJECT = "@PROJECTS/ADD_OR_UPDATE_PROJECT",

  UPDATE_PROJECT = "@PROJECTS/UPDATE_PROJECT",

  CLEAR_PROJECTS = "@PROJECTS/CLEAR_PROJECTS",

  CLEAR_PROJECTS_EXCEPT_OF_CURRENT = "@PROJECTS/CLEAR_PROJECTS_EXCEPT_OF_CURRENT",

  MARK_PROJECTS_AS_NOT_FETCHED = "@PROJECTS/MARK_PROJECTS_AS_NOT_FETCHED",

  REMOVE_MEMBERSHIP_FROM_PROJECT_AND_CHILDREN = "@PROJECTS/REMOVE_MEMBERSHIP_FROM_PROJECT_AND_CHILDREN",

  SET_IS_COMMON_CREATION_DISABLED = "@PROJECTS/SET_IS_COMMON_CREATION_DISABLED",

  DELETE_COMMON = "@PROJECTS/DELETE_COMMON",
}
