export enum GovernanceActions {
  // SEND_INVITATION = "SEND_INVITATION",

  CREATE_PROPOSAL = "CREATE_PROPOSAL",
  // VIEW_PROPOSAL = "VIEW_PROPOSAL",
  REPORT_PROPOSAL = "REPORT_PROPOSAL",

  // VIEW_COMMON = "VIEW_COMMON",

  CREATE_VOTE = "CREATE_VOTE",

  CREATE_MESSAGE = "CREATE_MESSAGE",
  REPORT_MESSAGE = "REPORT_MESSAGE",
  DELETE_MESSAGE = "DELETE_MESSAGE",

  CREATE_DISCUSSION = "CREATE_DISCUSSION",
  // VIEW_DISCUSSION = "VIEW_DISCUSSION",
  REPORT_DISCUSSION = "REPORT_DISCUSSION",
  DELETE_DISCUSSION = "DELETE_DISCUSSION",

  CONTRIBUTE = "CONTRIBUTE",
  RECEIVE_FUNDS = "RECEIVE_FUNDS",

  HIDE_OR_UNHIDE_MESSAGE = "HIDE_OR_UNHIDE_MESSAGE",
  HIDE_OR_UNHIDE_DISCUSSION = "HIDE_OR_UNHIDE_DISCUSSION",
  HIDE_OR_UNHIDE_PROPOSAL = "HIDE_OR_UNHIDE_PROPOSAL",

  RECEIVE_REPORT_MESSAGE_NOTIFICATION = "RECEIVE_REPORT_MESSAGE_NOTIFICATION",
  RECEIVE_REPORT_DISCUSSION_NOTIFICATION = "RECEIVE_REPORT_DISCUSSION_NOTIFICATION",
  RECEIVE_REPORT_PROPOSAL_NOTIFICATION = "RECEIVE_REPORT_PROPOSAL_NOTIFICATION",

  UPDATE_COMMON = "UPDATE_COMMON",
  CREATE_CIRCLE = "CREATE_CIRCLE",

  CREATE_SUBCOMMON = "CREATE_SUBCOMMON",
  CREATE_PROJECT = "CREATE_PROJECT",

  INVITE_TO_CIRCLE = "INVITE_TO_CIRCLE",
  INVITE_TO_COMMON = "INVITE_TO_COMMON",

  LEAVE_CIRCLE = "LEAVE_CIRCLE",

  PIN_OR_UNPIN_FEED_ITEMS = "PIN_OR_UNPIN_FEED_ITEMS",

  DELETE_COMMON = "DELETE_COMMON",

  MOVE_TO_HERE = "MOVE_TO_HERE",
  LINK_TO_HERE = "LINK_TO_HERE",
  MOVE_FROM_HERE = "MOVE_FROM_HERE",
  LINK_FROM_HERE = "LINK_FROM_HERE",
}
