export enum AuthActionTypes {
  SOCIAL_LOGIN = "@@AUTH/SOCIAL_LOGIN",
  SOCIAL_LOGIN_SUCCESS = "@@AUTH/SOCIAL_LOGIN_SUCCESS",
  SOCIAL_LOGIN_FAILURE = "@@AUTH/SOCIAL_LOGIN_FAILURE",

  LOGIN_WITH_FIREBASE_USER = "@@AUTH/LOGIN_WITH_FIREBASE_USER",
  LOGIN_WITH_FIREBASE_USER_SUCCESS = "@@AUTH/LOGIN_WITH_FIREBASE_USER_SUCCESS",
  LOGIN_WITH_FIREBASE_USER_FAILURE = "@@AUTH/LOGIN_WITH_FIREBASE_USER_FAILURE",

  WEBVIEW_LOGIN = "@@AUTH/WEBVIEW_LOGIN",
  WEBVIEW_LOGIN_SUCCESS = "@@AUTH/WEBVIEW_LOGIN_SUCCESS",
  WEBVIEW_LOGIN_FAILURE = "@@AUTH/WEBVIEW_LOGIN_FAILURE",

  WEBVIEW_LOGIN_WITH_USER = "@@AUTH/WEBVIEW_LOGIN_WITH_USER",
  WEBVIEW_LOGIN_WITH_USER_SUCCESS = "@@AUTH/WEBVIEW_LOGIN_WITH_USER_SUCCESS",
  WEBVIEW_LOGIN_WITH_USER_FAILURE = "@@AUTH/WEBVIEW_LOGIN_WITH_USER_FAILURE",

  LOGIN_USING_EMAIL_AND_PASSWORD = "@@AUTH/LOGIN_USING_EMAIL_AND_PASSWORD",
  LOGIN_USING_EMAIL_AND_PASSWORD_SUCCESS = "@@AUTH/LOGIN_USING_EMAIL_AND_PASSWORD_SUCCESS",
  LOGIN_USING_EMAIL_AND_PASSWORD_FAILURE = "@@AUTH/LOGIN_USING_EMAIL_AND_PASSWORD_FAILURE",

  SEND_VERIFICATION_CODE = "@@AUTH/SEND_VERIFICATION_CODE",
  SEND_VERIFICATION_CODE_SUCCESS = "@@AUTH/SEND_VERIFICATION_CODE_SUCCESS",
  SEND_VERIFICATION_CODE_FAILURE = "@@AUTH/SEND_VERIFICATION_CODE_FAILURE",

  CONFIRM_VERIFICATION_CODE = "@@AUTH/CONFIRM_VERIFICATION_CODE",
  CONFIRM_VERIFICATION_CODE_SUCCESS = "@@AUTH/CONFIRM_VERIFICATION_CODE_SUCCESS",
  CONFIRM_VERIFICATION_CODE_FAILURE = "@@AUTH/CONFIRM_VERIFICATION_CODE_FAILURE",

  LOG_OUT = "@@AUTH/LOG_OUT",

  UPDATE_USER_DATA = "@@AUTH/UPDATE_USER_DATA",
  UPDATE_USER_DATA_SUCCESS = "@@AUTH/UPDATE_USER_DATA_SUCCESS",
  UPDATE_USER_DATA_FAILURE = "@@AUTH/UPDATE_USER_DATA_FAILURE",

  SET_LOGIN_MODAL_STATE = "@@AUTH/SET_LOGIN_MODAL_STATE",

  START_AUTH_LOADING = "@@AUTH/START_AUTH_LOADING",
  STOP_AUTH_LOADING = "@@AUTH/STOP_AUTH_LOADING",

  SET_AUTH_PROVIDER = "@@AUTH/SET_AUTH_PROVIDER",

  SET_USER_PHONE_NUMBER = "@@AUTH/SET_USER_PHONE_NUMBER",

  SET_IS_AUTHENTICATION_READY = "@@AUTH/SET_IS_AUTHENTICATION_READY",

  DELETE_USER = "@@AUTH/DELETE_USER",
  DELETE_USER_SUCCESS = "@@AUTH/DELETE_USER_SUCCESS",
  DELETE_USER_FAILURE = "@@AUTH/DELETE_USER_FAILURE",

  SET_USER_STREAMS_WITH_NOTIFICATIONS_AMOUNT = "@@AUTH/SET_USER_STREAMS_WITH_NOTIFICATIONS_AMOUNT",
}
