export enum GovernanceConsequences {
  SUCCESSFUL_INVITATION = "SUCCESSFUL_INVITATION",

  POST_REPORTED = "POST_REPORTED",

  PROPOSAL_ACCEPTED = "PROPOSAL_ACCEPTED",
  PROPOSAL_REJECTED = "PROPOSAL_REJECTED",

  CORRECT_VOTE = "CORRECT_VOTE",
  WRONG_VOTE = "WRONG_VOTE",
}
