import React, { ReactElement } from "react";

interface AgendaIconProps {
  className?: string;
}

export default function AgendaIcon({
  className,
}: AgendaIconProps): ReactElement {
  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.961.508c.335.352.409 3.982.357 7.484a3.306 3.306 0 0 0-.651-.065c-1.841 0-3.334 1.48-3.334 3.304l.005.173c.03.576.21 1.125.515 1.599l.022.032-.287 1.428a1.815 1.815 0 0 0-.005.695l-.396.013c-3.079.088-6.979-.074-7.37-.485-.645-.677-.645-13.5 0-14.178.645-.677 10.5-.677 11.144 0zm-.294 8.74c1.104 0 2 .888 2 1.983 0 .574-.247 1.09-.64 1.453l.41 2.038a.511.511 0 0 1-.404.601.52.52 0 0 1-.366-.063l-.657-.39a.672.672 0 0 0-.686 0l-.657.39a.519.519 0 0 1-.708-.175.507.507 0 0 1-.064-.363l.411-2.038a1.968 1.968 0 0 1-.64-1.453 1.99 1.99 0 0 1 2-1.982zM4 8.589h3.333c.368 0 .667.296.667.66a.663.663 0 0 1-.589.657l-.078.004H4l-.078-.004a.663.663 0 0 1-.589-.656c0-.34.258-.618.59-.656L4 8.588zm6.667-2.642H4l-.078.004a.663.663 0 0 0-.589.656c0 .339.258.618.59.656L4 7.267h6.667l.077-.005a.663.663 0 0 0 .59-.656.664.664 0 0 0-.667-.66zM4 3.303h6.667c.368 0 .666.296.666.66a.663.663 0 0 1-.589.657l-.077.004H4l-.078-.004a.663.663 0 0 1-.589-.656c0-.339.258-.618.59-.656L4 3.303z"
        fill="currentColor"
      />
    </svg>
  );
}
