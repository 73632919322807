import React, { FC } from "react";

interface WhatsAppIconProps {
  className?: string;
}

const WhatsAppIcon: FC<WhatsAppIconProps> = (props) => {
  const { className } = props;

  return (
    <svg
      className={className}
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32.7202 24.1021C32.7202 24.2773 32.7148 24.6573 32.7043 24.9508C32.6785 25.669 32.6216 26.5957 32.5355 27.0179C32.4061 27.6525 32.2108 28.2514 31.9561 28.7499C31.6548 29.3396 31.2704 29.8678 30.8137 30.3237C30.3579 30.7787 29.8302 31.1615 29.2414 31.4617C28.7403 31.717 28.1376 31.9124 27.4995 32.0413C27.0814 32.1257 26.1617 32.1815 25.4481 32.2069C25.1543 32.2174 24.7742 32.2227 24.5997 32.2227L8.84325 32.2202C8.66804 32.2202 8.28812 32.2148 7.9946 32.2042C7.27641 32.1785 6.3497 32.1216 5.92744 32.0355C5.2929 31.9061 4.69396 31.7108 4.19548 31.4561C3.60575 31.1547 3.07756 30.7704 2.62167 30.3136C2.16669 29.8579 1.78383 29.3302 1.48375 28.7414C1.22832 28.2403 1.03289 27.6376 0.904053 26.9995C0.819682 26.5814 0.763841 25.6616 0.738435 24.948C0.727998 24.6543 0.722717 24.2742 0.722717 24.0997L0.725217 8.34322C0.725217 8.16801 0.730561 7.78806 0.741092 7.49454C0.766872 6.77635 0.823776 5.84964 0.909866 5.42741C1.03927 4.79287 1.23457 4.19393 1.48928 3.69542C1.79061 3.10572 2.175 2.57749 2.6317 2.12161C3.08743 1.66666 3.61516 1.28377 4.20398 0.983655C4.70511 0.728291 5.30781 0.532862 5.94594 0.404024C6.36395 0.319621 7.28375 0.26378 7.99735 0.238406C8.29115 0.227937 8.67123 0.222656 8.84572 0.222656L24.6022 0.225187C24.7774 0.225187 25.1573 0.230531 25.4508 0.241062C26.169 0.266842 27.0957 0.323746 27.518 0.409836C28.1525 0.539237 28.7514 0.734541 29.25 0.989249C29.8397 1.29058 30.3678 1.67497 30.8237 2.13167C31.2787 2.5874 31.6616 3.1151 31.9617 3.70395C32.2171 4.20508 32.4125 4.80774 32.5414 5.44587C32.6257 5.86392 32.6815 6.78369 32.707 7.49732C32.7174 7.79112 32.7227 8.17117 32.7227 8.34572L32.7202 24.1021V24.1021Z"
        fill="url(#paint0_linear_6881_4153)"
      />
      <path
        d="M25.2005 7.79386C23.0341 5.62561 20.1529 4.43091 17.0833 4.42969C10.7585 4.42969 5.61083 9.57535 5.6083 15.9002C5.60749 17.922 6.13587 19.8955 7.14002 21.635L5.51208 27.5794L11.5951 25.9843C13.2712 26.8981 15.1581 27.3797 17.0787 27.3805H17.0834C23.4076 27.3805 28.5557 22.2343 28.5582 15.9093C28.5595 12.8442 27.367 9.96211 25.2005 7.79386V7.79386ZM17.0834 25.4431H17.0795C15.3681 25.4425 13.6896 24.9829 12.2252 24.1142L11.8769 23.9076L8.26716 24.8542L9.23068 21.3358L9.00384 20.9751C8.04913 19.4571 7.54491 17.7024 7.54566 15.901C7.54772 10.644 11.8263 6.36711 17.0871 6.36711C19.6345 6.36807 22.0292 7.36116 23.8299 9.1633C25.6306 10.9654 26.6217 13.361 26.6208 15.9086C26.6187 21.166 22.3402 25.4431 17.0834 25.4431V25.4431ZM22.3149 18.3024C22.0281 18.1589 20.6185 17.4655 20.3557 17.3698C20.0929 17.2741 19.9018 17.2263 19.7106 17.5133C19.5195 17.8002 18.97 18.4458 18.8027 18.6371C18.6355 18.8284 18.4682 18.8524 18.1815 18.7089C17.8948 18.5654 16.9709 18.2627 15.8758 17.2862C15.0235 16.5263 14.448 15.5876 14.2808 15.3006C14.1135 15.0136 14.263 14.8586 14.4065 14.7156C14.5355 14.5872 14.6932 14.3809 14.8366 14.2135C14.9799 14.0462 15.0277 13.9265 15.1233 13.7353C15.2189 13.544 15.1711 13.3766 15.0994 13.2331C15.0277 13.0896 14.4543 11.6788 14.2154 11.1049C13.9827 10.546 13.7462 10.6216 13.5702 10.6129C13.4032 10.6046 13.2119 10.6028 13.0207 10.6028C12.8296 10.6028 12.519 10.6745 12.2562 10.9615C11.9934 11.2484 11.2527 11.9419 11.2527 13.3527C11.2527 14.7634 12.2801 16.1264 12.4234 16.3177C12.5668 16.509 14.4452 19.4042 17.3214 20.6457C18.0055 20.941 18.5395 21.1174 18.9559 21.2495C19.6428 21.4676 20.2678 21.4368 20.7619 21.363C21.3127 21.2808 22.4582 20.6697 22.6972 20.0001C22.9361 19.3306 22.9361 18.7567 22.8644 18.6372C22.7927 18.5176 22.6016 18.4458 22.3149 18.3024V18.3024Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_6881_4153"
          x1="16.7227"
          y1="0.222656"
          x2="16.7227"
          y2="32.2227"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#61FD7D" />
          <stop offset="1" stopColor="#2BB826" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default WhatsAppIcon;
