import React, { ReactElement } from "react";

interface ReplyIconProps {
  className?: string;
}

export default function ReplyIcon({ className }: ReplyIconProps): ReactElement {
  const color = "currentColor";

  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.87006 11.271L10.4401 5.62095C10.561 5.52754 10.7139 5.48565 10.8656 5.50437C11.0173 5.5231 11.1555 5.60093 11.2501 5.72095C11.3293 5.8241 11.3716 5.95089 11.3701 6.08095V9.08095C19.5701 9.08095 21.1201 14.771 21.3701 17.811C21.3731 17.9661 21.3169 18.1167 21.2129 18.2319C21.1088 18.3471 20.9648 18.4183 20.8101 18.4309C20.7216 18.4365 20.633 18.4213 20.5514 18.3865C20.4698 18.3518 20.3974 18.2985 20.3401 18.231C19.2253 16.9867 17.8205 16.0371 16.2504 15.4667C14.6802 14.8962 12.9935 14.7225 11.3401 14.961V17.961C11.3401 18.1121 11.28 18.2571 11.1731 18.364C11.0662 18.4709 10.9212 18.531 10.7701 18.531C10.6499 18.5281 10.534 18.4859 10.4401 18.411L2.87006 12.7309C2.76667 12.6599 2.67928 12.568 2.61348 12.4612C2.54768 12.3544 2.50494 12.2351 2.48799 12.1108C2.47104 11.9865 2.48026 11.86 2.51506 11.7395C2.54986 11.619 2.60947 11.5071 2.69006 11.411C2.74278 11.3556 2.80344 11.3084 2.87006 11.271Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
