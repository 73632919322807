import React, { FC } from "react";

interface NotionIconProps {
  className?: string;
  fill?: string;
  width?: number;
  height?: number;
}

const DEFAULT_PROPS = {
  fill: "#B7BCD2",
  width: 13,
  height: 13,
};

const NotionIcon: FC<NotionIconProps> = (props) => {
  const {
    className,
    fill = DEFAULT_PROPS.fill,
    width = DEFAULT_PROPS.width,
    height = DEFAULT_PROPS.height,
  } = props;

  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.742 6.166v2.682c0 .273-.016.547-.047.82-.035.469-.185.92-.437 1.317a2.387 2.387 0 0 1-.876.773 2.895 2.895 0 0 1-1.082.319c-.601.05-1.205.061-1.808.031h-4.51c-.401.002-.8-.047-1.188-.147a2.346 2.346 0 0 1-1.67-1.516 3.798 3.798 0 0 1-.2-.924 7.758 7.758 0 0 1-.035-1.105c.042-1.35.016-2.7.024-4.047v-1.08c0-.446.067-.89.2-1.315.194-.585.61-1.07 1.158-1.35.28-.138.582-.227.892-.263.37-.059.746-.087 1.121-.085H6.9c.568 0 1.134-.02 1.7-.026.276 0 .55 0 .826.016.434.009.865.057 1.29.145.38.077.74.237 1.052.468.377.301.652.71.79 1.171.09.288.145.586.166.887.04.474.015.95.015 1.424v1.815l.003-.01ZM6.76 11.32c.819 0 1.635.019 2.453.021.263 0 .555 0 .832-.031.213-.01.425-.048.629-.113a1.692 1.692 0 0 0 1.176-1.203c.086-.32.127-.65.124-.982V3.54a4.472 4.472 0 0 0-.092-1.053 1.656 1.656 0 0 0-1.316-1.316 4.154 4.154 0 0 0-.971-.097H5.076a8.353 8.353 0 0 0-1.405.026 7.484 7.484 0 0 0-.68.121 1.508 1.508 0 0 0-1.052.821c-.13.261-.207.546-.226.837-.021.321-.03.642-.03.963v4.51a8.32 8.32 0 0 0 .098 1.535c.049.305.178.59.374.829.239.27.56.455.913.526.338.076.683.11 1.03.1.889-.018 1.775-.02 2.662-.02Z"
        fill={fill}
      />
      <path
        d="M8.8 7.137v-3.75h-.263c-.093 0-.182-.021-.264-.032l-.047-.016a.163.163 0 0 1-.1-.234.471.471 0 0 1 .403-.305h2.055c.004.07.004.142 0 .213a.335.335 0 0 1-.229.284c-.14.05-.288.077-.437.082h-.097v6.04c0 .052 0 .073-.066.086-.221.055-.44.121-.66.169a.979.979 0 0 1-.577-.04.866.866 0 0 1-.39-.308c-.16-.229-.318-.46-.468-.697-.609-.955-1.215-1.91-1.818-2.866-.174-.263-.34-.553-.513-.829a2.234 2.234 0 0 0-.211-.263v4.211c0 .047 0 .06.06.065.177.019.35.048.527.071.05 0 .103 0 .153.021.123.021.165.095.118.214a.495.495 0 0 1-.403.3 1.768 1.768 0 0 1-.5 0 7.388 7.388 0 0 0-1.131 0l-.527.018a.395.395 0 0 1 .135-.442.963.963 0 0 1 .397-.158l.145-.026V3.418h-.095a1.602 1.602 0 0 1-.434-.026.182.182 0 0 1-.153-.263.363.363 0 0 1 .355-.306h2.071a.332.332 0 0 1 .303.164c.082.129.16.263.242.392l2.006 3.158c.123.194.263.387.384.581l.029.019Z"
        fill={fill}
      />
    </svg>
  );
};

export default NotionIcon;
