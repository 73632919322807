import React, { FC } from "react";

interface TrashIconProps {
  className?: string;
}

const TrashIcon: FC<TrashIconProps> = ({ className }) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.997 10.928c.053 3.374-.84 10.267-1.187 10.933-.444.852-10.663.852-11.551 0-.695-.665-1.39-7.543-1.235-10.922 3.832.252 10.217.248 13.973-.011zM12 1.5c2.142 0 3.89 1.473 3.994 3.325l.005.175.001.926 1.237.036.788.03.93.047.537.035c.251.019.476.038.673.058l.147.016c.188.021.339.043.455.065l.043.071c.254.516.254 2.171 0 2.687l-.044.071-.194.033-.247.032-.144.016c-.449.047-1.049.09-1.775.125l-.319.015c-1.544.069-3.54.108-5.636.114l-1.667-.003-1.115-.01c-1.28-.015-2.48-.044-3.51-.085l-.395-.016c-.762-.034-1.4-.074-1.892-.12l-.345-.036-.35-.048-.077-.014-.02-.042c-.159-.437-.069-2.056.161-2.787l.018-.049.29-.036.35-.034.41-.033.72-.045c.174-.01.356-.018.547-.027l.8-.032.787-.024.837-.02L8 5c0-1.933 1.79-3.5 3.999-3.5zm0 1.75c-1.054 0-1.918.714-1.994 1.62l-.005.13v.875h3.998V5c0-.876-.736-1.602-1.696-1.73l-.154-.015L12 3.25z"
      fill="currentColor"
    />
  </svg>
);

export default TrashIcon;
