import React, { FC } from "react";

interface QuestionOutlineIconProps {
  className?: string;
}

const QuestionOutlineIcon: FC<QuestionOutlineIconProps> = ({ className }) => {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0ZM10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2ZM10.7425 11.5054L10.7159 11.9153H9.0111C9.0111 11.2881 9.08657 10.7885 9.23751 10.4167C9.38846 10.0448 9.66482 9.67854 10.0666 9.31788C10.4684 8.95721 10.7358 8.66375 10.869 8.4375C11.0022 8.21125 11.0688 7.97267 11.0688 7.72177C11.0688 6.96461 10.7225 6.58602 10.03 6.58602C9.70144 6.58602 9.4384 6.68795 9.24084 6.8918C9.04329 7.09565 8.94007 7.37679 8.93119 7.73522H7C7.00888 6.87948 7.28302 6.20968 7.82242 5.72581C8.36182 5.24194 9.09767 5 10.03 5C10.9711 5 11.7014 5.22961 12.2209 5.68884C12.7403 6.14807 13 6.7966 13 7.63441C13 8.01523 12.9156 8.37478 12.7469 8.71304C12.5782 9.0513 12.283 9.42652 11.8613 9.83871L11.3219 10.3562C10.9845 10.6832 10.7913 11.0663 10.7425 11.5054ZM10.6792 14.711C10.4817 14.9037 10.2209 15 9.89678 15C9.5727 15 9.31188 14.9037 9.11432 14.711C8.91676 14.5184 8.81798 14.2742 8.81798 13.9785C8.81798 13.6783 8.91898 13.4308 9.12098 13.2359C9.32297 13.041 9.58158 12.9435 9.89678 12.9435C10.212 12.9435 10.4706 13.041 10.6726 13.2359C10.8746 13.4308 10.9756 13.6783 10.9756 13.9785C10.9756 14.2742 10.8768 14.5184 10.6792 14.711Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default QuestionOutlineIcon;
