import React, { FC } from "react";

interface TwitterIconProps {
  className?: string;
  color?: string;
}

const TwitterIcon: FC<TwitterIconProps> = (props) => {
  const { className, color = "#1DA1F2" } = props;

  return (
    <svg
      className={className}
      width="29"
      height="24"
      viewBox="0 0 29 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.52837 23.2227C20.0949 23.2227 25.8734 14.3748 25.8734 6.70222C25.8734 6.45094 25.8683 6.20082 25.8571 5.95173C26.9815 5.12943 27.9518 4.11129 28.7227 2.94505C27.6933 3.40758 26.5857 3.71889 25.4237 3.85924C26.6097 3.14044 27.5202 2.00326 27.9499 0.647754C26.8222 1.32389 25.5885 1.80054 24.302 2.05714C23.2537 0.928526 21.7613 0.222656 20.1087 0.222656C16.9363 0.222656 14.3637 2.82292 14.3637 6.02814C14.3637 6.48383 14.4142 6.92693 14.5128 7.35208C9.73826 7.10926 5.50457 4.79879 2.67116 1.2856C2.16084 2.1717 1.89242 3.17876 1.89331 4.20396C1.89331 6.21841 2.90745 7.99671 4.44973 9.03712C3.53745 9.00896 2.64521 8.7599 1.84801 8.31089C1.84715 8.33527 1.84715 8.35902 1.84715 8.38507C1.84715 11.197 3.8273 13.5448 6.45588 14.0768C5.9622 14.2127 5.45277 14.2814 4.9411 14.2811C4.57157 14.2811 4.21144 14.2443 3.86144 14.1764C4.5928 16.4834 6.71352 18.1623 9.22788 18.2092C7.26168 19.7668 4.78488 20.6946 2.09295 20.6946C1.63502 20.6949 1.17746 20.6681 0.722656 20.6141C3.26508 22.2612 6.28396 23.2223 9.52866 23.2223"
        fill={color}
      />
    </svg>
  );
};

export default TwitterIcon;
