import React, { FC } from "react";

interface GovernanceIconProps {
  className?: string;
}

const GovernanceIcon: FC<GovernanceIconProps> = ({ className }) => (
  <svg
    className={className}
    width="18"
    height="20"
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.20574 15.3561V7.87206H3.22721V15.3561H5.20574ZM1.51904 17.224H16.7451C16.8233 17.224 16.8984 17.2551 16.9537 17.3104C17.009 17.3657 17.04 17.4407 17.04 17.519V19.3254H1.23639V17.519C1.23639 17.4407 1.26747 17.3657 1.32278 17.3104C1.37809 17.2551 1.45311 17.224 1.53134 17.224H1.51904ZM9.24883 1.06398L14.1644 3.52178L17.1752 5.07021C17.2942 5.12613 17.3899 5.22174 17.446 5.3406C17.502 5.45947 17.5149 5.59413 17.4825 5.72148C17.452 5.84819 17.3795 5.96084 17.2769 6.04116C17.1743 6.12148 17.0475 6.16472 16.9172 6.16387H1.08893C0.95794 6.16644 0.829963 6.12443 0.726014 6.04469C0.622064 5.96494 0.548317 5.8522 0.516878 5.72501C0.485438 5.59782 0.498172 5.46372 0.552993 5.34473C0.607815 5.22573 0.701468 5.12896 0.818571 5.07021L3.82937 3.52178L8.74497 1.06398C8.8267 1.02194 8.91729 1 9.00919 1C9.1011 1 9.19167 1.02194 9.2734 1.06398H9.24883ZM15.0247 15.3561V7.87206H13.0584V15.3561H15.0247ZM10.1091 15.3561V7.87206H8.14281V15.3561H10.1091Z"
      stroke="currentColor"
    />
  </svg>
);

export default GovernanceIcon;
