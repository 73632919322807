import React, { ReactElement } from "react";

interface DeleteIconProps {
  className?: string;
}

export default function DeleteIcon({ className }: DeleteIconProps): ReactElement {
  return (
    <svg className={className} width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.665 7.285c.035 2.25-.56 6.845-.791 7.29-.297.567-7.109.567-7.701 0-.463-.444-.926-5.03-.824-7.282 2.555.167 6.811.165 9.316-.008zM8 1c1.428 0 2.593.982 2.663 2.217l.003.116v.617l.825.024.526.02.62.032.358.024c.167.012.317.025.448.038l.098.01c.125.015.226.03.304.044l.028.047c.17.344.17 1.448 0 1.792l-.029.047-.13.022-.164.022-.096.01c-.3.031-.7.06-1.183.083l-.213.01c-1.03.046-2.36.072-3.757.076L7.189 6.25l-.743-.006a87.51 87.51 0 0 1-2.34-.057l-.263-.01c-.508-.023-.934-.05-1.262-.08l-.23-.024-.233-.032-.051-.01-.014-.028c-.105-.291-.045-1.37.108-1.858l.012-.033.193-.024.233-.023.274-.021.48-.03.364-.018.534-.021.524-.017.558-.013.001-.61C5.334 2.043 6.528 1 8 1zm0 1.167c-.703 0-1.278.476-1.33 1.08l-.003.086v.584h2.666v-.584c0-.584-.49-1.068-1.131-1.153l-.103-.01L8 2.167z"
        fill="#979BBA"
        fillRule="nonzero"
      />
    </svg>
  );
}
