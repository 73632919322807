import React, { FC } from "react";

interface ThemeIconProps {
  className?: string;
}

const ThemeIcon: FC<ThemeIconProps> = ({ className }) => {
  const color = "currentColor";

  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        stroke={color}
        d="M10.8041 1.94321C12.9155 1.64079 15.0629 2.02536 16.9285 3.04001C16.9799 3.07003 17.0223 3.11325 17.0514 3.16524C17.073 3.20034 17.0873 3.23947 17.0936 3.28032C17.0998 3.32117 17.0979 3.36291 17.0878 3.40311C17.0777 3.44331 17.0597 3.48114 17.0348 3.51441C17.01 3.54767 16.9788 3.57569 16.943 3.59682C16.6435 3.78168 16.3535 3.98175 16.0742 4.19625C15.79 4.4166 15.5189 4.65357 15.2626 4.90588C13.4982 6.6312 12.485 8.98055 12.4453 11.4385C12.4055 13.8965 13.3424 16.2624 15.0505 18.017C15.3663 18.341 15.7064 18.6405 16.0678 18.9128C16.4258 19.1821 16.8019 19.4265 17.1935 19.6444C17.2443 19.669 17.2872 19.7071 17.3177 19.7545C17.3483 19.8019 17.3652 19.8569 17.3667 19.9134C17.3682 19.97 17.3542 20.026 17.3263 20.0754C17.2983 20.1248 17.2575 20.1658 17.2082 20.1938C15.9462 20.9618 14.5311 21.4404 13.0658 21.5948C11.6004 21.7493 10.1216 21.5758 8.73669 21.0869C7.35175 20.5981 6.09546 19.8061 5.05907 18.7685C4.02268 17.7309 3.23221 16.4738 2.74504 15.0885C2.25786 13.7031 2.08622 12.2242 2.24258 10.7593C2.39895 9.29437 2.87937 7.88014 3.64901 6.61928C4.41864 5.35843 5.45814 4.28261 6.69202 3.46996C7.9259 2.65731 9.32319 2.12821 10.7824 1.92111L10.8041 1.94321Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ThemeIcon;
